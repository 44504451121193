.bmd-layout-container {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.bmd-layout-drawer {
    position: absolute;
    z-index: 5;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    max-height: 100%;
    overflow: visible;
    overflow-y: auto;
    font-size: .875rem;
    background: #fff;
    transition: transform;
    will-change: transform;
    transform-style: preserve-3d;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0,0,0,.12);
    transition-duration: .2s;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
}
.bmd-drawer-f-l>.bmd-layout-drawer {
    top: 0;
    left: 0;
    width: 240px;
    height: 100%;
    transform: translateX(-250px);
}
.bmd-layout-content, .bmd-layout-header {
    transition-duration: .2s;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
}
.bmd-layout-content {
    position: relative;
    z-index: 1;
    display: inline-block;
    flex-grow: 1;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}
.bmd-drawer-f-l>.bmd-layout-content, .bmd-drawer-f-l>.bmd-layout-header {
    margin-left: 0;
}
:not(.bmd-drawer-out).bmd-drawer-overlay.bmd-drawer-overlay.bmd-drawer-f-l>.bmd-layout-content, :not(.bmd-drawer-out).bmd-drawer-overlay.bmd-drawer-overlay.bmd-drawer-f-l>.bmd-layout-header {
    width: 100%;
    margin-left: 0;
}

.bmd-layout-backdrop {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 4;
    width: 100%;
    height: 100%;
    visibility: hidden;
    background-color: transparent;
    transition-property: background-color;
    transition-duration: .2s;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
}
.bmd-layout-backdrop {
    background-color: rgba(0,0,0,.5);
    opacity: 0;
    transition-property: opacity;
    visibility: visible;
    pointer-events: none;
}