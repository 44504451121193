//
// alerts
// Extended from Bootstrap
//

//
// Bootstrap Overrides =====================================
//

// Allow for a text-decoration since links are the same color as the alert text.

.alert-link {
  text-decoration: $alert-link-text-decoration;
}

// Color variants
//
// Using Bootstrap's core alert-variant mixin to generate solid background color + yiq colorized text (and making close/links match those colors)
@mixin alert-variant($background, $border, $color) {
  --#{$prefix}alert-color: #{$color};
  --#{$prefix}alert-bg: #{$background};
  --#{$prefix}alert-border-color: #{$border};
  --#{$prefix}alert-link-color: #{shade-color($color, 20%)};

  @if $enable-gradients {
    background-image: var(--#{$prefix}gradient);
  }

  .alert-link {
    color: var(--#{$prefix}alert-link-color);
  }
}

@each $color, $value in $theme-colors {
  .alert-#{$color} {
    @include alert-variant(
      shift-color($value, $alert-bg-scale),
      shift-color($value, $alert-border-scale),
      color-contrast(shift-color($value, $alert-bg-scale))
    );
    // https://getbootstrap.com/docs/5.3/components/alerts/#sass-loops
    // --alert-color: var(--#{$prefix}#{$state}-text-emphasis);
    // --#{$prefix}alert-bg: var(--#{$prefix}#{$state}-bg-subtle);
    // --#{$prefix}alert-border-color: var(--#{$prefix}#{$state}-border-subtle);
    // --#{$prefix}alert-link-color: var(--#{$prefix}#{$state}-text-emphasis);

    // Divider
    hr {
      background-color: darken(shift-color($value, $alert-border-scale), 5%);
    }

    // Close
    .btn-close {
      padding: calc(#{$alert-padding-y} + #{$btn-close-padding-y})
        $alert-padding-x;
      background-image: escape-svg(
        url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{color-contrast(shift-color($value, $alert-bg-scale))}'><path d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/></svg>")
      );
    }

    // Link
    .alert-link {
      color: color-contrast(shift-color($value, $alert-bg-scale));
    }
  }
}
