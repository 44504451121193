//
// list-group.scss
// Extended from Bootstrap
//

//
// Bootstrap Overrides =====================================
//

// @see https://getbootstrap.com/docs/5.3/components/list-group/#sass-variables
@mixin list-group-item-variant($state, $background, $color) {
  .list-group-item-#{$state} {
    color: $color;
    background-color: $background;

    &.list-group-item-action {
      &:hover,
      &:focus {
        color: $color;
        background-color: shade-color($background, 10%);
      }

      &.active {
        color: $white;
        background-color: $color;
        border-color: $color;
      }
    }
  }
}

// Contextual variants
//
// Changing the Bootstrap color modifier classes to be full opacity background with yiq calculated font color

@each $color, $value in $theme-colors {
  @include list-group-item-variant($color, $value, color-yiq($value));
}

// List group sizing

.list-group-lg .list-group-item {
  padding-top: $list-group-item-padding-y-lg;
  padding-bottom: $list-group-item-padding-y-lg;
}

// List group flush

.list-group-flush > .list-group-item {
  padding-left: 0;
  padding-right: 0;
}

.list-group-flush:not(:last-child) > .list-group-item:last-child {
  border-bottom-width: $list-group-border-width;
}

// List group focus

.list-group-focus .list-group-item:focus .text-focus {
  color: var(--tenant-primary-color, $primary) !important;
}

//
// Theme ===================================
//

// Activity

.list-group-activity .list-group-item {
  border: 0;
}

.list-group-activity .list-group-item:not(:last-child)::before {
  content: '';
  position: absolute;
  top: $list-group-item-padding-y;
  left: $avatar-size-sm * 0.5;
  height: 100%;
  border-left: $border-width solid $border-color;
}
