//
// user-variables.scss
// Use this to overwrite Bootstrap and Dashkit variables
//

// Example of a variable override to change Dashkit's background color
// Remove the "//" to comment it in and see it in action!
// $body-bg: #12263F;
// $green:      #1DC887 !default;

$blue: #15c;
$navbar-dark-bg: #15c;
$navbar-dark-border-color: #15c;
$navbar-dark-color: #fff;
$navbar-dark-hover-color: #d2ddec;

// $path-to-fonts: '../../fonts';
// $path-to-img: '../../images';
